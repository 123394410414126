<template>
  <div>
    <b-card v-if="dadosPontos.length && !carregando">
      <b-container fluid class="ml-3 mt-2">
        <b-row>
          <b-col cols="10" class="mb-2">
            <h5 class="mt-2">
              Total de Medições Esperadas:
              {{ medicoesEsperadas.toLocaleString('pt-BR') }}
            </h5>
          </b-col>
          <b-col cols="2" class="mb-2">
            <download-excel :data="dadosFormatados" class="mb-2">
              <b-button variant="success">Exportar para Excel</b-button>
            </download-excel>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-table
              :items="dadosFormatados"
              :fields="tableFields"
              :striped="true"
              responsive="sm"
            >
              <template v-slot:cell(faltantes)="row">
                <a href="#" @click.prevent="showModal(row.item)">
                  {{ row.item.faltantes }}
                </a>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
    <b-modal
      v-model="showModalFlag"
      :title="modalTitle"
      size="lg"
      centered
      hide-footer
    >
      <b-list-group>
        <b-list-group-item v-for="date in modalData" :key="date">
          {{ date }}
        </b-list-group-item>
      </b-list-group>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue';
import JsonExcel from 'vue-json-excel';

Vue.component('downloadExcel', JsonExcel);

export default {
  data() {
    return {
      showModalFlag: false,
      modalData: [],
      modalTitle: ''
    };
  },
  props: ['dadosPontos', 'carregando', 'medicoesEsperadas', 'measurementType'],
  methods: {
    showModal(item) {
      this.modalTitle = 'Faltantes';
      this.modalData = [];

      if (this.measurementType === '5min') {
        // Check if the diasFaltantes and its datas property exist
        if (item.diasFaltantes && Array.isArray(item.diasFaltantes)) {
          this.modalData = item.diasFaltantes;
        } else {
          // Provide fallback or error message if datas is not available
          this.modalData = ['No missing data available'];
        }
      } else if (this.measurementType === '1hora') {
        // Check if horasFaltantes exists and is an array
        if (Array.isArray(item.horasFaltantes)) {
          this.modalData = item.horasFaltantes;
        } else {
          // Provide fallback or error message if horasFaltantes is not available
          this.modalData = ['No missing data available'];
        }
      }

      this.showModalFlag = true;
    },
    formatarPorcentagem(valor) {
      return (valor * 100).toFixed(2) + '%';
    }
  },
  computed: {
    tableFields() {
      // Adjust the table fields based on the measurementType
      if (this.measurementType === '5min') {
        return [
          { key: 'codPontoMedicao', label: 'PM', sortable: true },
          {
            key: 'qtdColetaDiaria',
            label: 'Coleta Diária',
            sortable: true
          },
          { key: 'pctColetaDiaria', label: '% CD', sortable: true },
          {
            key: 'qtdInspecaoLogica',
            label: 'Insp. Lógica',
            sortable: true
          },
          { key: 'pctInspecaoLogica', label: '% IL', sortable: true },
          {
            key: 'qtdApenasInspecaoLogica',
            label: 'Apenas Insp. Log',
            sortable: true
          },
          { key: 'faltantes', label: 'Faltantes', sortable: true },
          { key: 'pctFaltantes', label: '% F', sortable: true }
        ];
      } else {
        return [
          { key: 'codPontoMedicao', label: 'PM', sortable: true },
          { key: 'qtdMedidas', label: 'Medições Horárias', sortable: true },
          { key: 'pctMedidas', label: '% MH', sortable: true },
          { key: 'medidasZeradas', label: 'Medidas Zeradas', sortable: true },
          { key: 'pctMedidasZeradas', label: '% MZ', sortable: true },
          { key: 'faltantes', label: 'Faltantes', sortable: true },
          { key: 'pctFaltantes', label: '% F', sortable: true }
        ];
      }
    },
    dadosFormatados() {
      return this.dadosPontos.map((ponto) => {
        // Clone the object to avoid mutating the original data
        const formattedPonto = { ...ponto };

        if (this.measurementType === '5min') {
          // Apply the formatting to the percentage fields for the 5-minute data
          formattedPonto.pctColetaDiaria = this.formatarPorcentagem(
            formattedPonto.pctColetaDiaria
          );
          formattedPonto.pctInspecaoLogica = this.formatarPorcentagem(
            formattedPonto.pctInspecaoLogica
          );
          formattedPonto.pctApenasInspecaoLogica = this.formatarPorcentagem(
            formattedPonto.pctApenasInspecaoLogica
          );
          formattedPonto.pctFaltantes = this.formatarPorcentagem(
            formattedPonto.pctFaltantes
          );
        } else {
          // Apply the formatting to the percentage fields for the 1-hour data
          formattedPonto.pctMedidas = this.formatarPorcentagem(
            formattedPonto.pctMedidas
          );
          formattedPonto.pctFaltantes = this.formatarPorcentagem(
            formattedPonto.pctFaltantes
          );
          formattedPonto.pctMedidasZeradas = this.formatarPorcentagem(
            formattedPonto.pctMedidasZeradas
          );
        }

        return formattedPonto;
      });
    }
  }
};
</script>
